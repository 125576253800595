body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #cfd14a;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.login-container {
  display: flex;
  width: 100%;
  height: 1000px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-button {
  padding: 8px;
  margin: 8px;
}

.login-container {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.progress-number {
  font-weight: bold;
  font-size: 2em;
  color: white;
  margin-top: 12px;
  padding-bottom: 4px;
}

.progress-initialize {
  font-weight: bold;
  font-size: 2em;
  color: white;
  margin-top: 12px;
  padding-bottom: 4px;
  text-shadow: 2px 2px #6a6a6a;
}

.loading-workaround {
  font-weight: bold;
  font-size: 1.25em;
  color: rgb(102, 101, 101);
  margin-top: 12px;
  padding-bottom: 4px;
  /* text-shadow: 2px 2px #6a6a6a; */
}

.license {
  margin-top: 70px;
  font-size: 20px;
  color: white;
  text-shadow: 2px 2px #6a6a6a;
}

#license-login {
  margin: 0 auto;
  text-align: center;
}

#error-msg {
  font-family: comic sans ms;
}

#loading-text {
  margin: 0 auto;
  margin-top: 18px;
  text-align: center;
  font-size: 36px;
  color: white;
  font-family: comic sans ms;
  text-shadow: 2px 2px #6a6a6a;
}

.button-see-detail {
  background-color: #804623;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 28px;
  margin: 35px 2px;
  cursor: pointer;
  font-family: comic sans ms;
  border-radius: 13px;
  border: none;
}

.progress-wrapper {
  background-color: #0000006b;
  width: 172px;
  margin: 0 auto;
  border-radius: 18px;
}

.loading-wrapper {
  margin: 0 auto;
  text-align: center;
}

#morning-moon-load-logo {
  width: 30%;
  height: 40%;
}

@media only screen and (max-width: 600px) {
  #morning-moon-load-logo {
    width: 70%;
    height: 40%;
  }

  .license {
    /* margin-top: 24px; */
    padding: 9px;
  }
}

.announcement-confirm {
  padding: 7px 19px !important;
  border-radius: 8px !important;
  width: 200px !important;
  height: 60px !important;
  background-color: #ffffff !important;
  font-size: 36px !important;
  font-family: "tahoma";
  color: #9996B1 !important;
  border: 1px solid #D2D2D0 !important;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3) !important;
}

.popup-kub {
  padding: 7px 19px !important;
  border-radius: 8px !important;
  width: 160px !important;
  height: 50px !important;
  background-color: #ffffff !important;
  font-size: 21px !important;
  font-family: "tahoma";
  color: #9996B1 !important;
  border: 1px solid #D2D2D0 !important;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3) !important;
}

.grecaptcha-badge {
  visibility: hidden;
}